export const palette = {
    primary: "#8E31FF",
    white: "#FFFFFF",
    black: "#000000",
}

const colors = {
    ...palette
}

export default colors