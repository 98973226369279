import { SVGProps } from "react";

const TikTokIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "41"}
      height={props.height || "41"}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.15269 0C3.72181 0 0.129883 3.59193 0.129883 8.0228V32.0912C0.129883 36.522 3.72181 40.114 8.15269 40.114H32.2211C36.6519 40.114 40.2439 36.522 40.2439 32.0912V8.0228C40.2439 3.59193 36.6519 0 32.2211 0H8.15269ZM4.14128 8.0228C4.14128 5.80737 5.93725 4.0114 8.15269 4.0114H32.2211C34.4366 4.0114 36.2325 5.80737 36.2325 8.0228V32.0912C36.2325 34.3067 34.4366 36.1026 32.2211 36.1026H8.15269C5.93725 36.1026 4.14128 34.3067 4.14128 32.0912V8.0228Z"
        fill="#101010"
      />
      <g clipPath="url(#clip0_715_1084)">
        <path
          d="M20.7625 8.65491H23.6696C23.881 9.7047 24.4624 11.029 25.4829 12.3431C26.4813 13.6308 27.8056 14.5279 29.5719 14.5279V17.4643C26.9981 17.4643 25.0644 16.2692 23.699 14.7789V24.8055C23.699 26.2575 23.2684 27.6768 22.4618 28.884C21.6551 30.0913 20.5086 31.0322 19.1671 31.5879C17.8257 32.1435 16.3496 32.2889 14.9256 32.0056C13.5015 31.7224 12.1935 31.0232 11.1668 29.9965C10.1401 28.9698 9.44092 27.6617 9.15766 26.2377C8.8744 24.8136 9.01978 23.3376 9.57542 21.9962C10.1311 20.6547 11.072 19.5082 12.2792 18.7015C13.4865 17.8949 14.9058 17.4643 16.3578 17.4643V20.4008C15.4866 20.4008 14.635 20.6591 13.9107 21.1431C13.1863 21.6271 12.6217 22.315 12.2884 23.1199C11.955 23.9247 11.8678 24.8104 12.0377 25.6648C12.2077 26.5192 12.6272 27.3041 13.2432 27.9201C13.8592 28.5361 14.644 28.9556 15.4985 29.1256C16.3529 29.2955 17.2385 29.2083 18.0434 28.8749C18.8482 28.5415 19.5362 27.977 20.0202 27.2526C20.5042 26.5283 20.7625 25.6767 20.7625 24.8055V8.65491Z"
          fill="#101010"
        />
      </g>
      <defs>
        <clipPath id="clip0_715_1084">
          <rect
            width="23.4918"
            height="23.4918"
            fill="white"
            transform="translate(7.54883 8.65491)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TikTokIcon;