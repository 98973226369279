import { Routes, Route, useLocation } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { Box, Spacer, Spinner } from "@chakra-ui/react";
import Loader from "../components/Loader";
const fakedelay = (promise: any) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 5000);
  }).then(() => promise);
};
const Home = lazy(() => import("../pages/Home"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Students = lazy(() => import("../pages/Students"));
const Teachers = lazy(() => import("../pages/Teachers"));
const Blogs = lazy(() => import("../pages/Blogs"));
const Registration = lazy(() => import("../pages/Registration"));
const Team = lazy(() => import("../pages/Team"));

const Navigator = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever location changes
  }, [location]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/about"
        element={
          <Suspense fallback={<Loader />}>
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="/students"
        element={
          <Suspense fallback={<Loader />}>
            <Students />
          </Suspense>
        }
      />
      <Route
        path="/teachers"
        element={
          <Suspense fallback={<Loader />}>
            <Teachers />
          </Suspense>
        }
      />
      <Route
        path="/blogs"
        element={
          <Suspense fallback={<Loader />}>
            <Blogs />
          </Suspense>
        }
      />
      <Route
        path="/registration"
        element={
          <Suspense fallback={<Loader />}>
            <Registration />
          </Suspense>
        }
      />
      <Route
        path="/about/team"
        element={
          <Suspense fallback={<Loader />}>
            <Team />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Navigator;
