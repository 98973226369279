import { Box, Center } from "@chakra-ui/react";

const DownloadButton = ({
  bg = "white",
  color = "primary",
}: {
  bg?: string;
  color?: string;
}) => {
  return (
    <Center
      position={"relative"}
      minW={{ base: 125, md: 150, lg: 160 }}
      borderColor={"black"}
      // borderWidth={1}
      justifyContent={"center"}
      onClick={() => window.open("https://forms.office.com/e/nSe27zJhHR")}
    >
      <Box
        bg={bg}
        p={2}
        fontFamily={"Urbanist-SemiBold"}
        textColor={color}
        borderRadius={"lg"}
        cursor={"pointer"}
        borderWidth={3}
        borderColor={"#8E31FF"}
        fontSize={{ base: 12, md: 16 }}
      >
        Join the waiting list
      </Box>
      {/* <Box
        bg={"white"}
        borderRadius={4}
        alignItems={"center"}
        justifyContent={"center"}
        borderWidth={1}
        position={"absolute"}
        // right={{ base: 1, md: 0, lg: "20%" }}
        right={1}
        top={{ base: -3 }}
        fontFamily={"Urbanist-SemiBold"}
        textColor={"primary"}
        fontSize={12}
        px={1}
        borderColor={"primary"}
      >
        Beta
      </Box> */}
    </Center>
  );
};

export default DownloadButton;
