import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import SymphomeLogo from "../assets/svg/SymphomeLogo";
import HeaderBlob from "../assets/svg/HeaderBlob";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "../assets/svg/MenuIcon";
import { useEffect, useState } from "react";
import CloseIcon from "../assets/svg/CloseIcon";

const pages = [
  { title: "Home", route: "/" },
  { title: "Students", route: "/students" },
  { title: "Teachers", route: "/teachers" },
  // { title: "Blogs", route: "/blogs" },
  // { title: "Sign Up", route: "/registration" },
  { title: "About Us", route: "/about" },
];

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  useEffect(() => {
    if (openDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openDrawer]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleNavigate = ({ route }: { route: string }) => {
    setOpenDrawer(!openDrawer);
    navigate(route);
  };
  return (
    <Box zIndex={1000}>
      <Box height={100}>
        <Box position={"absolute"} zIndex={1} w={{ base: "60%", md: "30%" }}>
          <HeaderBlob width={"100%"} height={48} />
        </Box>

        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          px={"2%"}
          h={12}
          mb={2}
          bg={"#F1E7FF"}
          // borderWidth={1}
          borderColor={"black"}
          boxShadow={"0 4px 6px -1px rgba(142, 49, 255, 0.6)"}
        >
          <Flex
            gap={"2%"}
            w={"20%"}
            h={"100%"}
            borderColor={"black"}
            zIndex={1000}
            alignItems={"center"}
          >
            <Heading size={"md"}>SymphoMe</Heading>
            <Box>
              <SymphomeLogo width={30} height={30} />
            </Box>
          </Flex>
          <Flex
            gap={"5%"}
            w={"40%"}
            alignItems={"center"}
            fontFamily={"Urbanist-SemiBold"}
            fontSize={"sm"}
            zIndex={1000}
            display={{ base: "none", md: "flex" }}
          >
            {pages.map((page, index) => {
              return (
                <Box
                  cursor={"pointer"}
                  key={index}
                  textColor={
                    page.route === pathname && page.route === "/"
                      ? "black"
                      : page.route !== "/" && pathname.startsWith(page.route)
                      ? "black"
                      : "#8E31FF"
                  }
                >
                  <Link to={page.route}>{page.title}</Link>
                </Box>
              );
            })}
          </Flex>
          <Flex alignItems={"center"} gap={4}>
            {/* <Box>
              <SearchIcon width={18} height={18} display={"none"} />
            </Box> */}
            <Box
              display={{ base: "flex", md: "none" }}
              onClick={() => setOpenDrawer(!openDrawer)}
              cursor={"pointer"}
            >
              {openDrawer ? (
                <CloseIcon width={24} height={24} color="black" />
              ) : (
                <MenuIcon width={24} height={24} color="black" />
              )}
            </Box>
          </Flex>
        </Flex>

        {openDrawer && (
          <Box
            position={"relative"}
            top={-2}
            zIndex={-1}
            w={"100%"}
            h={"100vh"}
            bg={"#F1E7FF"}
          >
            <Flex flexDirection={"column"} px={16} py={14} gap={10}>
              {pages.map((page, index) => {
                return (
                  <Text
                    cursor={"pointer"}
                    key={index}
                    fontFamily={"Urbanist-SemiBold"}
                    fontSize={18}
                    textColor={
                      page.route === pathname && page.route === "/"
                        ? "black"
                        : page.route !== "/" && pathname.startsWith(page.route)
                        ? "black"
                        : "#8E31FF"
                    }
                    onClick={() => {
                      handleNavigate({ route: page.route });
                    }}
                  >
                    {page.title}
                  </Text>
                );
              })}
            </Flex>
          </Box>
        )}

        {/* {!openDrawer && (
          <Flex
            gap={4}
            boxShadow={"0 4px 4px -3px rgba(142, 49, 255, 0.6)"}
            py={2}
            justifyContent={"center"}
            fontSize={{ base: "2xs", md: "sm" }}
            textAlign={"center"}
            alignItems={"center"}
            fontFamily={"Urbanist-SemiBold"}
            px={2}
            bg={"white"}
            position={"relative"}
            top={-2}
            zIndex={-1}
          >
            SymphoMe is hosting the 2025 MusicGig conference and festival in
            Glasgow from 8am - 6pm on the 25th June.
            <Flex textColor={"#8E31FF"} gap={2} cursor={"pointer"}>
              Learn More and Register
              <ForwardArrow />
            </Flex>
          </Flex>
        )} */}
      </Box>
    </Box>
  );
};

export default Header;
