import { SVGProps } from "react";

const HeaderBlob = (props:SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "511"}
      height={props.height || "64"}
      viewBox="0 0 511 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M401 -23C-34.3918 -23 -1303 -27 -1303 -27V139H508.792C508.792 139 532.5 74 401 -23Z"
        fill={props.color || "#DDC2FF"}
      />
    </svg>
  );
};
export default HeaderBlob;