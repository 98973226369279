import { SVGProps } from "react";

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "41"}
      height={props.height || "41"}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26694 0C3.83607 0 0.244141 3.59193 0.244141 8.0228V32.0912C0.244141 36.522 3.83607 40.114 8.26694 40.114H32.3354C36.7661 40.114 40.3582 36.522 40.3582 32.0912V8.0228C40.3582 3.59193 36.7661 0 32.3354 0H8.26694ZM4.25554 8.0228C4.25554 5.80737 6.05151 4.0114 8.26694 4.0114H32.3354C34.5508 4.0114 36.3468 5.80737 36.3468 8.0228V32.0912C36.3468 34.3067 34.5508 36.1026 32.3354 36.1026H8.26694C6.05151 36.1026 4.25554 34.3067 4.25554 32.0912V8.0228Z"
        fill="#101010"
      />
      <path
        d="M20.9587 20.4008V31.43H18.4767V20.4008V19.6841H17.76H14.5614V17.2021H17.76H18.4767V16.4855V14.383C18.4767 12.4985 18.9358 11.2758 19.6597 10.5228C20.3788 9.77479 21.4738 9.37156 23.0075 9.37156C23.9714 9.37156 24.6469 9.41596 25.1099 9.46173V11.8536H23.5703C22.7465 11.8536 22.021 12.0747 21.5364 12.6808C21.0826 13.2484 20.9587 14.0145 20.9587 14.8127V16.4855V17.2021H21.6753H24.9651L24.6108 19.6841H21.6753H20.9587V20.4008Z"
        fill="#101010"
        stroke="#101010"
        strokeWidth="1.4333"
      />
    </svg>
  );
};

export default FacebookIcon;