import { SVGProps } from "react";

const SymphomeLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "42"}
      height={props.height || "42"}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 41.1272C9.95408 41.1272 1 32.1732 1 21.1272V20.8731C1 9.82711 9.95408 0.873047 21 0.873047C32.046 0.873047 41 9.82711 41 20.8731V21.1272C41 32.1732 32.046 41.1272 21 41.1272Z"
        fill="white"
        stroke="#8E31FF"
        strokeWidth="0.998221"
        strokeMiterlimit="10"
      />
      <path
        d="M16.7783 30.7061C16.7783 33.3258 20.356 33.2281 20.356 30.7061"
        stroke="#8E31FF"
        strokeWidth="0.998221"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1453 31.7618C27.8015 31.7618 29.9547 29.6086 29.9547 26.9525C29.9547 24.2963 27.8015 22.1431 25.1453 22.1431C22.4892 22.1431 20.3359 24.2963 20.3359 26.9525C20.3359 29.6086 22.4892 31.7618 25.1453 31.7618Z"
        fill="white"
        stroke="#8E31FF"
        strokeWidth="0.921443"
        strokeMiterlimit="10"
      />
      <path
        d="M25.1451 30.4517C27.0778 30.4517 28.6445 28.8849 28.6445 26.9522C28.6445 25.0194 27.0778 23.4526 25.1451 23.4526C23.2123 23.4526 21.6455 25.0194 21.6455 26.9522C21.6455 28.8849 23.2123 30.4517 25.1451 30.4517Z"
        fill="#8E31FF"
        stroke="#8E31FF"
        strokeWidth="0.921443"
        strokeMiterlimit="10"
      />
      <path
        d="M23.7775 27.6561C24.9652 27.6561 25.928 26.6932 25.928 25.5055C25.928 24.3178 24.9652 23.355 23.7775 23.355C22.5898 23.355 21.627 24.3178 21.627 25.5055C21.627 26.6932 22.5898 27.6561 23.7775 27.6561Z"
        fill="white"
      />
      <path
        d="M29.9541 9.53271V27.0694"
        stroke="#8E31FF"
        strokeWidth="0.921443"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7525 11.6248C30.5208 11.0578 29.9343 10.6473 29.9343 10.6473C29.9343 10.6473 29.817 9.41561 30.0125 9.49381C30.0125 9.49381 31.3419 10.1781 32.0653 10.53C34.1181 11.5466 35.2129 11.6248 34.9197 12.1918C34.6069 12.7783 32.6518 12.0354 31.7525 11.6248Z"
        fill="#8E31FF"
        stroke="#8E31FF"
        strokeWidth="0.79939"
        strokeMiterlimit="10"
      />
      <path
        d="M11.909 31.8395C14.5652 31.8395 16.7184 29.6862 16.7184 27.0301C16.7184 24.3739 14.5652 22.2207 11.909 22.2207C9.25287 22.2207 7.09961 24.3739 7.09961 27.0301C7.09961 29.6862 9.25287 31.8395 11.909 31.8395Z"
        fill="white"
        stroke="#8E31FF"
        strokeWidth="0.921443"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9087 30.5293C13.8415 30.5293 15.4082 28.9625 15.4082 27.0298C15.4082 25.0971 13.8415 23.5303 11.9087 23.5303C9.97599 23.5303 8.40918 25.0971 8.40918 27.0298C8.40918 28.9625 9.97599 30.5293 11.9087 30.5293Z"
        fill="#8E31FF"
        stroke="#8E31FF"
        strokeWidth="0.921443"
        strokeMiterlimit="10"
      />
      <path
        d="M10.5412 27.7337C11.7289 27.7337 12.6917 26.7709 12.6917 25.5832C12.6917 24.3954 11.7289 23.4326 10.5412 23.4326C9.35345 23.4326 8.39062 24.3954 8.39062 25.5832C8.39062 26.7709 9.35345 27.7337 10.5412 27.7337Z"
        fill="white"
      />
      <path
        d="M8.66602 20.7939C9.60443 20.657 11.0316 20.2856 11.9896 19.8555"
        stroke="#8E31FF"
        strokeWidth="1.1518"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SymphomeLogo;
