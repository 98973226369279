import { SVGProps } from "react";

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "41"}
      height={props.height || "41"}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.76201 6.28836C0.561523 8.64445 0.561523 11.7288 0.561523 17.8974V23.0374C0.561523 29.206 0.561523 32.2904 1.76201 34.6463C2.818 36.7189 4.50297 38.4039 6.57547 39.4598C8.93157 40.6603 12.0159 40.6603 18.1845 40.6603H23.3245C29.4931 40.6603 32.5775 40.6603 34.9334 39.4598C37.006 38.4039 38.691 36.7189 39.7469 34.6463C40.9475 32.2904 40.9475 29.206 40.9475 23.0374V17.8974C40.9475 11.7288 40.9475 8.64445 39.7469 6.28836C38.691 4.21586 37.006 2.53089 34.9334 1.4749C32.5775 0.274414 29.4931 0.274414 23.3245 0.274414H18.1845C12.0159 0.274414 8.93157 0.274414 6.57547 1.4749C4.50297 2.53089 2.818 4.21586 1.76201 6.28836ZM23.3245 3.94586H18.1845C15.0396 3.94586 12.9017 3.94873 11.2493 4.08372C9.63962 4.21524 8.81648 4.4536 8.24227 4.74618C6.86061 5.45018 5.73729 6.5735 5.03329 7.95516C4.74072 8.52937 4.50235 9.35251 4.37084 10.9621C4.23584 12.6146 4.23297 14.7525 4.23297 17.8974V23.0374C4.23297 26.1824 4.23584 28.3201 4.37084 29.9726C4.50235 31.5823 4.74072 32.4055 5.03329 32.9797C5.73729 34.3612 6.86061 35.4845 8.24227 36.1885C8.81648 36.4811 9.63962 36.7196 11.2493 36.851C12.9017 36.986 15.0396 36.9889 18.1845 36.9889H23.3245C26.4695 36.9889 28.6072 36.986 30.2597 36.851C31.8694 36.7196 32.6926 36.4811 33.2668 36.1885C34.6484 35.4845 35.7716 34.3612 36.4756 32.9797C36.7683 32.4055 37.0067 31.5823 37.1382 29.9726C37.2731 28.3201 37.276 26.1824 37.276 23.0374V17.8974C37.276 14.7525 37.2731 12.6146 37.1382 10.9621C37.0067 9.35251 36.7683 8.52937 36.4756 7.95516C35.7716 6.5735 34.6484 5.45018 33.2668 4.74618C32.6926 4.4536 31.8694 4.21524 30.2597 4.08372C28.6072 3.94873 26.4695 3.94586 23.3245 3.94586Z"
        fill="#101010"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7442 29.8984C25.3728 29.8984 29.9356 25.4773 29.9356 20.0236C29.9356 14.5699 25.3728 10.1488 19.7442 10.1488C14.1156 10.1488 9.55273 14.5699 9.55273 20.0236C9.55273 25.4773 14.1156 29.8984 19.7442 29.8984ZM19.7442 26.6068C23.4965 26.6068 26.5385 23.6593 26.5385 20.0236C26.5385 16.3878 23.4965 13.4404 19.7442 13.4404C15.9918 13.4404 12.9499 16.3878 12.9499 20.0236C12.9499 23.6593 15.9918 26.6068 19.7442 26.6068Z"
        fill="#101010"
      />
      <path
        d="M29.9339 8.50305C28.9958 8.50305 28.2354 9.23991 28.2354 10.1489C28.2354 11.0578 28.9958 11.7947 29.9339 11.7947C30.8721 11.7947 31.6325 11.0578 31.6325 10.1489C31.6325 9.23991 30.8721 8.50305 29.9339 8.50305Z"
        fill="#101010"
      />
    </svg>
  );
};

export default InstagramIcon;
