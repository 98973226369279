import React from "react";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import Navigator from "./routes";
import Header from "./components/Header";
import theme from "./theme/extendedTheme";
import Footer from "./components/Footer";

function App() {
  return (
    <ChakraProvider theme={theme}>x
      <Flex direction="column" minHeight="100vh">
        <Box position="fixed" top="0" width="100%" zIndex="1000">
          <Header />
        </Box>
        <Box flex="1">
          <Navigator />
          {/* <Box display="flex" justifyContent="center" alignItems="center" flex="1" fontSize="5xl" fontWeight="bold">
              Coming Soon!
            </Box> */}
        </Box>
        <Footer />
      </Flex>
    </ChakraProvider>
  );
}

export default App;
