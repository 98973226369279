import { extendTheme } from "@chakra-ui/react";
import colors from "./color";

const theme = extendTheme({
  fonts: {
    body: "Urbanist-Medium",
    heading: "Urbanist-SemiBold"
  },
  colors : {
    ...colors
  }
});

export default theme;