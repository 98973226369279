import { Box, Center, Flex, Spacer, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Center h={"100vh"}>
      <Spinner size={"xl"} speed="0.65s" thickness="4px" color="#8E31FF" />
    </Center>
  );
};
export default Loader;
